import { render, staticRenderFns } from "./ECommerceShop.vue?vue&type=template&id=206a6d5a&scoped=true&"
import script from "./ECommerceShop.vue?vue&type=script&lang=js&"
export * from "./ECommerceShop.vue?vue&type=script&lang=js&"
import style0 from "./ECommerceShop.vue?vue&type=style&index=0&id=206a6d5a&prod&lang=scss&"
import style1 from "./ECommerceShop.vue?vue&type=style&index=1&id=206a6d5a&prod&lang=scss&scoped=true&"
import style2 from "./ECommerceShop.vue?vue&type=style&index=2&id=206a6d5a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206a6d5a",
  null
  
)

export default component.exports