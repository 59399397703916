<template>
  <div>
    <Header class="" />
    <div style="height: inherit" class="timeline">
      <!-- ECommerce Header -->

      <!-- Overlay -->
      <div class="body-content-overlay" />

      <!-- Searchbar -->
      <div class="ecommerce-searchbar mt-1">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="filters.q"
                placeholder="Search Product"
                class="search-product"
              />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- Prodcuts -->
      <section :class="[itemView, 'd-flex', 'justify-content-center']">
        <b-card id="postWidth" class="ecommerce-card" no-body>
          <profile-post :posts="profileData" />
          <!-- <div class="item-img text-center">
          <b-link :to="{ name: 'apps-e-commerce-product-details', params: { slug: product.slug } }">
            <b-img
              :alt="`${product.name}-${product.id}`"
              fluid
              class="card-img-top"
              :src="product.image"
            />
          </b-link>
        </div> -->

          <!-- Product Details -->
          <!-- <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div>
            <div>
              <h6 class="item-price">
                ${{ product.price }}
              </h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'apps-e-commerce-product-details', params: { slug: product.slug } }"
            >
              {{ product.name }}
            </b-link>
            <b-card-text class="item-company">
              By <b-link class="ml-25">
                {{ product.brand }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            {{ product.description }}
          </b-card-text>
        </b-card-body> -->

          <!-- Product Actions -->
          <!-- <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                ${{ product.price }}
              </h4>
            </div>
          </div>
          <b-button
            variant="light"
            tag="a"
            class="btn-wishlist"
            @click="toggleProductInWishlist(product)"
          >
            <feather-icon
              icon="HeartIcon"
              class="mr-50"
              :class="{'text-danger': product.isInWishlist}"
            />
            <span>Wishlist</span>
          </b-button>
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="handleCartActionClick(product)"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              class="mr-50"
            />
            <span>{{ product.isInCart ? 'View In Cart' : 'Add to Cart' }}</span>
          </b-button>
        </div> -->
        </b-card>
      </section>

      <!-- Pagination -->
      <!-- <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalProducts"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section> -->

      <!-- Sidebar -->
      <portal to="content-renderer-sidebar-detached-left">
        <shop-left-filter-sidebar
          :filters="filters"
          :filter-options="filterOptions"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        />
      </portal>
    </div>
    <Footer class="footer-timeline" />
  </div>
</template>

<script>
import store from "@/store";

import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { watch } from "@vue/composition-api";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import ShopLeftFilterSidebar from "./ECommerceShopLeftFilterSidebar.vue";
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
} from "./useECommerceShop";
import { useEcommerceUi } from "../useEcommerce";
import ProfilePost from "../../../pages/profile/ProfilePost.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    Header,
    Footer,
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,

    // SFC
    ShopLeftFilterSidebar,
    ProfilePost,
  },
  setup() {
    const { filters, filterOptions, sortBy, sortByOptions } = useShopFiltersSortingAndPagination();

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();

    const { itemView, itemViewOptions, totalProducts } = useShopUi();

    const { products, fetchProducts } = useShopRemoteData();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      }).then((response) => {
        products.value = response.data.products;
        totalProducts.value = response.data.total;
      });
    };
    fetchShopProducts();
    watch(
      [filters, sortBy],
      () => {
        fetchShopProducts();
      },
      {
        deep: true,
      }
    );

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
      profileData: [
        {
          avatar: "/img/avatar-s-18.0ffcd7b4.jpg",
          username: "Leeanna Alvord",
          postTime: "12 Dec 2018 at 1:16 AM",
          postText:
            "Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.",
          postImg: "/img/2.fedb99d5.jpg",
          likes: 1240,
          youLiked: true,
          comments: 1240,
          share: 1240,
          likedUsers: [
            {
              avatar: "/img/avatar-s-1.3fdebd01.jpg",
              username: "Trine Lynes",
            },
            {
              avatar: "/img/avatar-s-2.da5e73c7.jpg",
              username: "Lilian Nenes",
            },
            {
              avatar: "/img/avatar-s-3.6128b34a.jpg",
              username: "Alberto Glotzbach",
            },
            {
              avatar: "/img/avatar-s-5.50ed9b46.jpg",
              username: "George Nordic",
            },
            {
              avatar: "/img/avatar-s-4.61de186b.jpg",
              username: "Vinnie Mostowy",
            },
          ],
          likedCount: 140,
          detailedComments: [
            {
              avatar: "/img/avatar-s-6.0f4533ab.jpg",
              username: "Kitty Allanson",
              comment:
                "Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.",
              commentsLikes: 34,
              youLiked: false,
            },
            {
              avatar: "/img/avatar-s-8.d6c23222.jpg",
              username: "Jackey Potter",
              comment:
                "Unlimited color🖌 options allows you to set your application color as per your branding 🤪.",
              commentsLikes: 61,
              youLiked: true,
            },
          ],
        },
        {
          avatar: "/img/avatar-s-22.da5e73c7.jpg",
          username: "Rosa Walters",
          postTime: "11 Dec 2019 at 1:16 AM",
          postText:
            "Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.",
          postImg: "/img/25.290aefb5.jpg",
          likes: 1240,
          youLiked: true,
          comments: 1240,
          share: 1240,
          likedUsers: [
            {
              avatar: "/img/avatar-s-1.3fdebd01.jpg",
              username: "Trine Lynes",
            },
            {
              avatar: "/img/avatar-s-2.da5e73c7.jpg",
              username: "Lilian Nenes",
            },
            {
              avatar: "/img/avatar-s-3.6128b34a.jpg",
              username: "Alberto Glotzbach",
            },
            {
              avatar: "/img/avatar-s-5.50ed9b46.jpg",
              username: "George Nordic",
            },
            {
              avatar: "/img/avatar-s-4.61de186b.jpg",
              username: "Vinnie Mostowy",
            },
          ],
          likedCount: 271,
          detailedComments: [
            {
              avatar: "/img/avatar-s-3.6128b34a.jpg",
              username: "Kitty Allanson",
              comment:
                "Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.",
              commentsLikes: 34,
              youLiked: false,
            },
          ],
        },
        {
          avatar: "/img/avatar-s-15.d50eb9cb.jpg",
          username: "Charles Watson",
          postTime: "12 Dec 2019 at 1:16 AM",
          postText:
            "Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.",
          postVid: "https://www.youtube.com/embed/6stlCkUDG_s",
          likes: 1240,
          youLiked: true,
          comments: 1240,
          share: 1240,
          likedUsers: [
            {
              avatar: "/img/avatar-s-1.3fdebd01.jpg",
              username: "Trine Lynes",
            },
            {
              avatar: "/img/avatar-s-2.da5e73c7.jpg",
              username: "Lilian Nenes",
            },
            {
              avatar: "/img/avatar-s-3.6128b34a.jpg",
              username: "Alberto Glotzbach",
            },
            {
              avatar: "/img/avatar-s-5.50ed9b46.jpg",
              username: "George Nordic",
            },
            {
              avatar: "/img/avatar-s-4.61de186b.jpg",
              username: "Vinnie Mostowy",
            },
          ],
          likedCount: 264,
          detailedComments: [
            {
              avatar: "/img/avatar-s-3.6128b34a.jpg",
              username: "Kitty Allanson",
              comment:
                "Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.",
              commentsLikes: 34,
              youLiked: false,
            },
          ],
        },
      ],

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
  created() {
    // $themeConfig.layout.menu.hidden = true;
    // console.log(store.state.appConfig.layout.menu.hidden);
    // console.log(store.state.appConfig.layout.footerType);
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
#postWidth {
  width: 800px;
}
@media only screen and (max-width: 600px) {
  #postWidth {
    width: unset;
  }
}
</style>
<style scoped>
.timeline {
  margin-top: 70px;
}
@media (min-width: 992px) {
  [dir="ltr"] .sidebar-left {
    float: left;
    margin-top: 56px;
  }
}
@media (min-width: 992px) {
  .footer-timeline {
    margin-left: -260px;
  }
}
</style>
